import React from 'react';
import '../../css/carouselAnimate.css';
//img
import SlideImg1 from '../../img/slide/sliderImg1.png';
import SlideImg2 from '../../img/slide/sliderImg2.png';
import SlideImg3 from '../../img/slide/sliderImg3.png';
import SlideImg4 from '../../img/slide/sliderImg4.png';
import SlideImg5 from '../../img/slide/sliderImg5.png';
import SlideImg6 from '../../img/slide/sliderImg6.png';
import SlideImg7 from '../../img/slide/sliderImg7.png';
import SlideImg8 from '../../img/slide/sliderImg8.png';
import SlideImg9 from '../../img/slide/sliderImg9.png';
import SlideImg10 from '../../img/slide/sliderImg10.png';
import SlideImg11 from '../../img/slide/sliderImg11.png';
import SlideImg12 from '../../img/slide/sliderImg12.png';

//slider
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';

import Style from '../../css/carousel.css';

const AutoplaySlider = withAutoplay(AwesomeSlider); 

const CarouselImg = () => {
    

    return ( 
        <div style={{margin:'100px 0'}}>
        <AutoplaySlider 
            play={true}
            cancelOnInteraction={false} // should stop playing on user interaction
            interval={2000}
            animation="cubeAnimation"
            cssModule={Style}
        >
            <div data-src={SlideImg1}  className='img-carousel'></div>
            <div data-src={SlideImg2} className='img-carousel'></div>
            <div data-src={SlideImg3} className='img-carousel'></div>
            <div data-src={SlideImg4} className='img-carousel'></div>    
            <div data-src={SlideImg5} className='img-carousel'></div>    
            <div data-src={SlideImg6} className='img-carousel'></div>    
            <div data-src={SlideImg7} className='img-carousel'></div>    
            <div data-src={SlideImg8} className='img-carousel'></div>    
            <div data-src={SlideImg9} className='img-carousel'></div>    
            <div data-src={SlideImg10} className='img-carousel'></div>    
            <div data-src={SlideImg11} className='img-carousel'></div>    
            <div data-src={SlideImg12} className='img-carousel'></div>    
        </AutoplaySlider>
        
      </div>
     );
}
 
export default CarouselImg;

