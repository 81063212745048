import React, { useState,lazy, Suspense } from 'react';
//Css
import '../../css/dispensersStyles.css';
//Slider
//Imgs Interior
import Interior1 from '../../img/dispenserStyles/interior/interior1.png';
import Interior2 from '../../img/dispenserStyles/interior/interior2.png';
import Interior3 from '../../img/dispenserStyles/interior/interior3.png';
import Interior4 from '../../img/dispenserStyles/interior/interior4.png';
import Interior5 from '../../img/dispenserStyles/interior/interior5.png';
import Interior6 from '../../img/dispenserStyles/interior/interior6.png';
import InteriorWebp1 from '../../img/dispenserStyles/interior/interior1.webp';
import InteriorWebp2 from '../../img/dispenserStyles/interior/interior2.webp';
import InteriorWebp3 from '../../img/dispenserStyles/interior/interior3.webp';
import InteriorWebp4 from '../../img/dispenserStyles/interior/interior4.webp';
import InteriorWebp5 from '../../img/dispenserStyles/interior/interior5.webp';
import InteriorWebp6 from '../../img/dispenserStyles/interior/interior6.webp';
//Imgs Exterior
import Exterior1 from '../../img/dispenserStyles/exterior/exterior1.png';
import Exterior2 from '../../img/dispenserStyles/exterior/exterior2.png';
import Exterior3 from '../../img/dispenserStyles/exterior/exterior3.png';
import Exterior4 from '../../img/dispenserStyles/exterior/exterior4.png';
import Exterior5 from '../../img/dispenserStyles/exterior/exterior5.png';
import Exterior6 from '../../img/dispenserStyles/exterior/exterior6.png';
import Exterior7 from '../../img/dispenserStyles/exterior/exterior7.png';
import Exterior8 from '../../img/dispenserStyles/exterior/exterior8.png';
import Exterior9 from '../../img/dispenserStyles/exterior/exterior9.png';
import Exterior10 from '../../img/dispenserStyles/exterior/exterior10.png';
import Exterior11 from '../../img/dispenserStyles/exterior/exterior11.png';
import Exterior12 from '../../img/dispenserStyles/exterior/exterior12.png';
import Exterior13 from '../../img/dispenserStyles/exterior/exterior13.png';
import ExteriorWebp1 from '../../img/dispenserStyles/exterior/exterior1.webp';
import ExteriorWebp2 from '../../img/dispenserStyles/exterior/exterior2.webp';
import ExteriorWebp3 from '../../img/dispenserStyles/exterior/exterior3.webp';
import ExteriorWebp4 from '../../img/dispenserStyles/exterior/exterior4.webp';
import ExteriorWebp5 from '../../img/dispenserStyles/exterior/exterior5.webp';
import ExteriorWebp6 from '../../img/dispenserStyles/exterior/exterior6.webp';
import ExteriorWebp7 from '../../img/dispenserStyles/exterior/exterior7.webp';
import ExteriorWebp8 from '../../img/dispenserStyles/exterior/exterior8.webp';
import ExteriorWebp9 from '../../img/dispenserStyles/exterior/exterior9.webp';
import ExteriorWebp10 from '../../img/dispenserStyles/exterior/exterior10.webp';
import ExteriorWebp11 from '../../img/dispenserStyles/exterior/exterior11.webp';
import ExteriorWebp12 from '../../img/dispenserStyles/exterior/exterior12.webp';
import ExteriorWebp13 from '../../img/dispenserStyles/exterior/exterior13.webp';


const SliderStyle = lazy( () => import('./SliderStyle.js'))

const DispensersStyles = () => {

    const [firstImgIn, setFirstImgIn] = useState(Interior1)
    const [firstImgEx, setFirstImgEx] = useState(Exterior1)
    const [imgsInterior] = useState([
        {url:Interior1, urlWebp:InteriorWebp1},
        {url:Interior2, urlWebp:InteriorWebp2},
        {url:Interior3, urlWebp:InteriorWebp3},
        {url:Interior4, urlWebp:InteriorWebp4},
        {url:Interior5, urlWebp:InteriorWebp5},
        {url:Interior6, urlWebp:InteriorWebp6},
    ])
    const [imgsExterior] = useState([
        {url:Exterior1, urlWebp:ExteriorWebp1},
        {url:Exterior2, urlWebp:ExteriorWebp2},
        {url:Exterior3, urlWebp:ExteriorWebp3},
        {url:Exterior4, urlWebp:ExteriorWebp4},
        {url:Exterior5, urlWebp:ExteriorWebp5},
        {url:Exterior6, urlWebp:ExteriorWebp6},
        {url:Exterior7, urlWebp:ExteriorWebp7},
        {url:Exterior8, urlWebp:ExteriorWebp8},
        {url:Exterior9, urlWebp:ExteriorWebp9},
        {url:Exterior10, urlWebp:ExteriorWebp10},
        {url:Exterior11, urlWebp:ExteriorWebp11},
        {url:Exterior12, urlWebp:ExteriorWebp12},
        {url:Exterior13, urlWebp:ExteriorWebp13},
      
    ])


    return (
        <section className='dispensers-container'>
            <div className='dispensers-style'>
                <div style={{width:'100%',height:'10%',display:'flex',flexDirection:'column' ,alignItems:'center'}}>
                    <h2>EXTERIOR</h2>
                    <h3>Panneau d'affichage customizable sur mesure</h3>
                </div>
                <img alt='diferent dispenser' className='first-img' src={firstImgEx} loading='lazy' />
                <Suspense
                    fallback={<h2>Cargando...</h2>}
                >
                <SliderStyle
                    imgs={imgsExterior}
                    setFirstImgIn={setFirstImgEx}
                />
                </Suspense>
            </div>

            <div className='dispensers-style'>
                <div style={{width:'100%',height:'10%',display:'flex',flexDirection:'column' ,alignItems:'center'}}>
                    <h2>INTERIOR</h2>
                    <h3>Couleurs personnalisables à la demande</h3>
                </div>
                <img alt='diferent dispenser' className='first-img' src={firstImgIn} />
                <Suspense
                    fallback={<h2>Cargando...</h2>}

                >
                <SliderStyle
                    imgs={imgsInterior}
                    setFirstImgIn={setFirstImgIn}
                />
                </Suspense>
                
            </div>
        </section>
    );
}
 
export default DispensersStyles;