import React, {} from 'react';
//modal
import {CustomDialog} from 'react-st-modal';
import ModalEmail from '../ui/ModalEmail';
//img
import Logo from '../../img/logoPlus.svg';
import WhatsappIcon from '../../img/whatsappWhite.svg';
import EmailIcon from '../../img/emailBlue.svg';
import '../../css/nav.css'

const Nav = () => {


    return ( 
        <div className='nav-container'>
            <div>
                <img src={Logo} alt='Logo' className='img-nav'/>
            </div>
            <nav >
                <h1>Contact us</h1>
                <a href='https://api.whatsapp.com/send?phone=41762374030&text=Hello%F0%9F%91%8B%F0%9F%8F%BB' className='whatsapp-icon' >
                    <img src={WhatsappIcon} alt='Whatsapp' className='a-icon'/>
                </a>
                <div style={{cursor:'pointer'}}
                    onClick={ async () => {
                        await CustomDialog(<ModalEmail /> , {
                        title: 'Contact us',
                        showCloseIcon: true,
                        })
                    }}
                >
                    <img src={EmailIcon} alt='Email' className='a-icon'/>
                </div>
               
            </nav>
         
        </div>
     );
}
 
export default Nav;