import React from 'react';
const View360 = () => {
    return ( 
        <div class="sketchfab-embed-wrapper"  > 
        <iframe title='10' frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share width="640" height="480" src="https://sketchfab.com/models/bf08346c9ded43048b8670419e9051c4/embed"> </iframe> 
        
        </div>
     );
}
 
export default View360;