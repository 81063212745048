import React from 'react';
//css
import '../../css/section4.css';
// import ReactPlayer from 'react-player';
import Img2 from '../../img/section4Img2.png';
import Img2Webp from '../../img/section4Img2.webp';


const Section4 = () => {
    return (  
        <section className='section4'>
            <div className='img-background'>
                <h1>Partout avec tout le monde</h1>
                <p>Notre intention est de créer une communauté où nous partageons des valeurs et une culture de sensibilisation à l'environnement, pleine de clients amoureux de la nature où ils vivent des expériences avec nos stations de remplissage d'eau.</p>
            </div>
            <div className='container-zeroplastic'>
                <h2>#ZeroPlasticCommunity</h2>
                <picture>
                     <source alt='diferent dispenser' className='img-zeroplastic'  srcset={Img2Webp} type='image/webp'/>
                    <img src={Img2} alt='Img Zero Plastic' className='img-zeroplastic' />
                </picture>
            </div>
        </section>
    );
}
 
export default Section4;






