import React, { useState } from 'react';
import '../../css/modalEmail.css';


const ModalEmail = ({email}) => {

    const [emaiUser, setEmaiUser] = useState(email)

    return ( 
          <form  name='contact' method='POST' data-netlify="true" className='form-container'>
            <input type="hidden" name="form-name" value="contact"/>

            <div>
              <label>
                Name:
                <input type='text' name='name' required='required'/>

              </label>
              <label>
                Email:
                <input type='text' name='email' required='required'  value={emaiUser} onChange={t => setEmaiUser(t.target.value)}  />
              </label>
            </div> 
            
            <label>
              Message:
              <textarea name='menssage' cols='25' rows='10'></textarea>
            </label>
            <input type='submit' value='Send' className='buttom-send' />          
          </form>
      );
}
export default ModalEmail;

// const ModalEmail = () => {
//   return ( 
//     <div>
//       <button
//         onClick={ async () => {
//           const result = await CustomDialog(<ModalEmailConntent /> , {
//             title: 'Contact us',
//             showCloseIcon: true,
//           })
//         }}
//       >
//         Open
//       </button>
//     </div>
//    );
// }
 
 
