import React from 'react';
import '../../css/header.css';
//Img
import Dispenser from '../../img/dispenser/dispenser.jpeg';
//Modal
import {CustomDialog} from 'react-st-modal';
import ModalEmail from '../ui/ModalEmail';


const Header = () => {


    return ( 
        <div className='header-container'>
            <div className='header-text'>
                <p className='header-text-p'>Réduis, <br/>Réutilise, <br/>Recycle,</p>
                <h1>Remplis.</h1>
                <p className='header-text-p-small'>Dites bonjour à la nouvelle station d’eau durable Suisse.</p>
                <button 
                    onClick={ async () => {
                        await CustomDialog(<ModalEmail /> , {
                        title: 'Contact us',
                        showCloseIcon: true,
                        })
                    }}
                >
                    J'en veux une !
                </button>
            </div>
              <img src={Dispenser} alt='Logo' className='img-contact-header' />
           
        </div>
     );
}
 
export default Header;