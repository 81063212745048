import React, { } from 'react';
import Nav from './components/ui/Nav';
import Header from './components/ui/Header';
import Section1 from './components/ui/Section1';
import CarouselImg from './components/ui/CarouselImg';
import Section2 from './components/ui/Section2';
import Section3 from './components/ui/Section3';
import Section4 from './components/ui/Section4';
import Footer from './components/ui/Footer';
import  './css/globals.css';
import Video1 from './components/ui/video1';
import DispensersStyles from './components/ui/DispensersStyles';


function App() {
  
  return (
    <>
      <body>
        <Nav/>
        <Header/>
        <Video1/>
        <Section1/> 
        <CarouselImg/>
        <Section2/>
        <DispensersStyles/>
        <Section3/>
        <Section4/>
        <Footer/>
      </body>
    </>
  );
}

export default App;
