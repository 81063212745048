import React from 'react';
import '../../css/section1.css';
import DropIcon from '../../img/drop.svg';
import BottleIcon from '../../img/bottle.svg';
import HandIcon from '../../img/hand.svg';
import Eye from '../../img/eye.svg';
import Arrow from '../../img/arrowUp.svg';



const Section1 = () => {
    return ( 
        <section className='section1-container'>
            <h1 style={{textAlign:'center'}}>Ce que notre station d’eau vous apportera…</h1>
            <div className='section1-icons-container'>
                <div className='section1-icons'>
                    <img src={DropIcon} alt='icon' />
                    <h2>Service à la communauté</h2>
                    <p>Un accès à l’eau filtrée, gratuite et bonne pour la santé.</p>
                </div>
                <div className='section1-icons'>
                    <img src={HandIcon} alt='icon'  fill={'#fffffff'}/>
                    <h2>Engagement</h2>
                    <p>L’association de vôtre ville/institution/entreprise à une projet écologique et durable.</p>
                </div>
                <div className='section1-icons'>
                    <img src={Eye} alt='icon' />
                    <h2>Visibilité</h2>
                    <p>La diffusion de vos messages/publicitésdans des emplacement à haute fréquentation.</p>
                </div>
                <div className='section1-icons'>
                    <img src={BottleIcon} alt='icon' className='section1-icons-bottle' />
                    <h2>Empreinte verte</h2>
                    <p>Une diminution des déchets plastiques afin de préserver l’environnement.</p>
                </div>
                <div className='section1-icons'>
                    <img src={Arrow } alt='icon' />
                    <h2>Rentabilité</h2>
                    <p>Un retour sur investissement, grâce à la possibilité de location des espaces publicitaires visibles 24h/24.</p>
                </div>
            </div>
        </section>
     );
}
 
export default Section1;