import React from 'react';
import ReactPlayer from 'react-player';
//videos
import VideoHead from '../../img/videos/video1.mp4';
import '../../css/video1.css';




const Video1 = () => {
    return ( 
        <div className='container-video1'>
            <ReactPlayer
                url={VideoHead}
                className='react-player'
                playing='true'
                width='100%'
                height='100%'
                loop='true'
                muted={true}
            />
            <p>Nous avons lancé 369 Water pour offrir à la population une hydratation saine et durable, en encourageant l’utilisation de bouteilles réutilisables, afin de réduire l’empreinte plastique.</p>
        </div>
     );
}
 
export default Video1;