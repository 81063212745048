import React, { useState,useEffect } from 'react';
import {CustomDialog} from 'react-st-modal';
import View360 from './View360';
//CSS
import '../../css/section2.css';
import '../../css/buttonDay.css'
//IMG
import Logo from '../../img/logoSkyBlue.png';
import DispenserDiaExterior from '../../img/dispenser/renderDiaExterior.png';
import DispenserNightExterior from '../../img/dispenser/renderNocheExterior.png';
import Buttom360 from '../../img/botton3D.svg'
//Parts
import PanelSolar from '../../img/dispenser/panelSolar.png';
import PetsHydratation from '../../img/dispenser/petsHydratation.png';
import WaterDispenser from '../../img/dispenser/waterDispenser.png';


const Section2 = () => {

    const [dark, setDark] = useState(true);
    const [styleSection, setStyleSection] = useState({
        backGroundType: '#FFFFFF',
        textStyle: '#000000'
    })
    const [dispenser, setDispenser] = useState(DispenserDiaExterior);
    const [optionRender, setOptionRender] = useState(true);
    const [textOption, setTextOption] = useState('ejemplo');

    const changeRender = () =>{
        if (dark === true) {
            setStyleSection({
                backGroundType:'rgba(0, 0, 0, 0.75)',
                textStyle: '#FFFFFF'
               
            })
                setDispenser(DispenserNightExterior);
                return;
            }
        
        setStyleSection({
            backGroundType:'#FFFFFF',
            textStyle: '#000000'
        })
   
        setDispenser(DispenserDiaExterior); 
    }

    useEffect(() => {
        changeRender();
    },[dark]);


    return ( 
        <section className='section2' style={{backgroundColor:styleSection.backGroundType}}>
            
            <div className='control-container'>
                <div className='wrapper'>
                    <label htmlFor='day'>   
                        <input className='switch' type='checkbox'  id='day' value={dark} onChange={() => setDark(!dark) } />
                    </label>
                </div>
                <div>
                    <p style={{color:styleSection.textStyle}}>Chez 369 Water, <br/>chaque partie à <br/>une fonction <br/>important.</p>
                </div>
                <div>
                    <img src={Logo} alt='Logo' className='logo-section2' />
                </div>

            </div>


            <div className='render-container'>
                <img src={dispenser} alt='Logo' className='img-render-day'/>
                <div className='options-render-container' style={{display:optionRender ? 'block' :'none'}}>
                    <div className='option1-render option-render'>
                        <p style={{color:styleSection.textStyle}}>Water Dispenser</p>
                        <button 
                            aria-label='Water Dispenser'
                            onClick={() => {setDispenser(WaterDispenser);setTextOption('Water Dispenser');setOptionRender(false) }}
                        ></button>
                    </div>
                    <div className='option2-render option-render'>
                        <p style={{color:styleSection.textStyle}}>Solar Panel</p>
                        <button 
                            aria-label='Solar Panel'
                            onClick={() => {setDispenser(PanelSolar);setTextOption('Solar Panel');setOptionRender(false) }}
                        ></button>
                    </div>
                    <div className='option3-render option-render'>
                        <p style={{color:styleSection.textStyle}}>Pet Hydratation</p>
                        <button 
                            aria-label='Pet Hydratation'
                            onClick={() => {setDispenser(PetsHydratation);setTextOption('Pet Hydratation');setOptionRender(false) }}
                        ></button>
                    </div>
                    <button
                        className='button360'
                        onClick={ async () => {
                            await CustomDialog(<View360 /> , {
                            title: 'View 360',
                            showCloseIcon: true,
                            })
                        }}

                    >
                        <img src={Buttom360} alt='Logo' />

                    </button>
                </div>

                <div className='options-render-container' style={{display:optionRender ? 'none' :'block'}}>
                     <div className='option-none-render option-render'>
                        <p style={{color:styleSection.textStyle}}>{textOption}</p>
                        <button 
                            aria-label={textOption}
                            style={{background:'#3DD9CF'}}
                            onClick={() => {setDispenser(!dark ? DispenserDiaExterior : DispenserNightExterior); setOptionRender(true); }}
                        ></button>
                    </div>
                </div>
            </div>

        </section>
     );
}
 
export default Section2;