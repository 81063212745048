import React from 'react';
//css
import '../../css/section3.css';
import Img from '../../img/section3.png'

const Section3 = () => {
    return ( 
        <div className='section3-container'>
            <img src={Img} alt='Logo' className='img-section3' />
        </div>
     );
}
 
export default Section3;