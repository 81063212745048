import React, { useState } from 'react';
import Logo from '../../img/logoWhite.svg';
import WhatsappIcon from '../../img/whatsapp.svg';
import EmailIcon from '../../img/emailBlue.svg';
import Arrow from '../../img/arrowRight.svg';
import '../../css/footer.css';
//Modal
import {CustomDialog} from 'react-st-modal';
import ModalEmail from '../ui/ModalEmail';



const Footer = () => {

    const [email, setEmail] = useState();

    return ( 
        <footer className='footer-container'>
            <div className='footer-email'>
                <p>Inscrivez-vous pour recevoir les actualités,<br/>les lancements et offres spéciales - <br/>directement dans votre boîte e-mail</p>
                <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',height:'23px'}}>
                    <input name='email' placeholder='Email Address' onChange={ t => setEmail(t.target.value)}/>
                    <button
                        onClick={ async () => {
                            await CustomDialog(<ModalEmail email={email}/> , {
                            title: 'Contact us',
                            showCloseIcon: true,
                            })
                        }}
                    >
                        <img src={Arrow} alt='Whatsapp' className='arrow-icon'/>
                    </button>
                </div>
            </div>
            <div className='img-contact-container'>

            <img src={Logo} alt='Logo' className='img-contact-logo'/>
            </div>

            <div className='footer-contact'>
                <h3>CONTACT US</h3>
                <div className='footer-redes'>
                    <a href='https://api.whatsapp.com/send?phone=41762374030&text=Hello%F0%9F%91%8B%F0%9F%8F%BB'  >
                        <img src={WhatsappIcon} alt='Whatsapp' className='a-icon a-icon-wsp'/>
                    </a>
                    <div
                        onClick={ async () => {
                            await CustomDialog(<ModalEmail /> , {
                            title: 'Contact us',
                            showCloseIcon: true,
                            })
                        }}
                    >
                        <img src={EmailIcon} alt='Email' className=' a-icon-email a-icon'/>
                    </div>
                </div>

            </div>
            
        </footer>
     );
}
 
export default Footer;